export const environment = {
    production: false,
    apiGateway: {
        url: "https://api-sysval.sc.geappl.io/",
        stage: "Yamazumi"
    },
    AllowScreenAuth: false,
    cognitoOptions: {
        ClientId: '6momgtan28o12pevla32ug4pet', // Your client id here
        AppWebDomain: 'mesauth.auth.us-east-1.amazoncognito.com', // Exclude the "https://" part.
        TokenScopesArray: ['openid', 'profile'],
        UserPoolId: 'us-east-1_s1pKZcsUG',
    },
    SSRSUrl: "http://proficy-reports/ReportServer?"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.